import {useState} from 'react';
export default function useCounter(){
   
const [adult, setadult] = useState(1);
//decrease counter ADULT
const decrease = () => {
  if (adult > 0) {
    setadult(count => count - 1);
  }
};


//increase counter ADULT
const increase = () => {
    if (adult < 4) {
      setadult(count => count + 1);
    }
};
// counter for adult start



    return[adult,setadult,increase, decrease];
}

