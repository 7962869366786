
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery'; 
import React from "react";
import axiosBaseURL from "./member/httpCommon";
export default function FooterPage(){ 
  
  $('#reset').click(function () {
    if (!$('#confirm').is(':checked')) {
        alert('Kindly click on the Privacy Policy Button');
        return false;
    }
   
});
  //  onClick={() => alert('Button clicked!')}
const[user,setUser]=useState({
    subscribe_email:"",    
})


//inputHandler
const inputHandler=(e)=>{
  setUser({...user,[e.target.name]:e.target.value})
}

const RegisterUser=(e)=>{
  e.preventDefault();
  axiosBaseURL.post(`/subscribeenquiry`,user)
  .then(()=>{
      alert("Submitted Successfully1")
      setUser(null);
      e.target.reset();
  }).catch(err=>{console.log(err)});
}


 const [toggle, setToggle] = useState(false)

//  footer hotel list api
const[hlist, sethlist] = useState([]);

const allhlist = ()=> {
    axiosBaseURL.get('/getcitieslist').then((res)=> {
      sethlist(res.data.cities_list)
      // console.log(res.data.cities_list)
    }).catch((err) => {
        // console.log(err)
    })
}
const [on, setOnState] = React.useState(false);
const toggle1 = () => setOnState(o => !o);

useEffect(()=>{
  allhlist();
},[]);

    return(
<div>

<footer>
  <article className="container">
    <div className="row">
      <div className="col-xsl-12">
        <div className="top-footer-heading">
          <h2>
    
       <span onClick={() => setToggle(!toggle)} >
                 <p className="tgl_btn" onClick={toggle1}>
                 Our Presence <span class="pin" >{on ? '−' : '+'} {on}</span>
    </p>
            </span>
             
      {toggle && (
          <div className="hotels animate__animated animate__fadeIn">
          <div className="">
       
          <ul className="footer-hotel-listing">
         {
                            hlist.map((val) =>
              <li  key={val.id}> 
                 <Link to={val.city_slug}>HOTELS IN {val.name}</Link>     </li>
                          )
                        } 
            </ul>
          </div>
        </div>
      )}
          </h2>
        </div>
      
      </div>
      <div className="col-xs-12">
        <ul className="bottom-footer-links">
    
          <li>
          <Link to="/awards">Awards</Link>           
          </li>
          <li>
          <Link to="/AboutUs">About us</Link>     
          </li>
          <li>
          <Link to="https://careers.lemontreehotels.com/">Careers</Link>  
                      </li>
          <li>
          <Link to="/ContactUs">Contact us</Link>           
          </li>
          <li>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>            
          </li>
          <li>
            <a href="http://brochures.lemontreehotels.com/bookcase/bqlzc/">
              Newsletters
            </a>
          </li>
        </ul>
        <ul className="bottom-footer-links">
          <li>
            <a href="https://blog.lemontreehotels.com">Blogs</a>
          </li>
          <li>
          <Link to="/DayUseRoom">Day Use</Link> 
          </li>
          <li>
            <a href="https://www.lemontreehotels.com/assets/pdf/cookie_policy.pdf" target="_blank">
              cookies Policy
            </a>
          </li>
          <li>
          <Link to="/NonAffiliation">Non Affiliation</Link>   
          </li>
          <li>
          <Link to="/term-condition">Terms &amp; Conditions</Link>   
         </li>
        </ul>
        <ul className="bottom-footer-links">
          <li>
          <Link to="/media">Media</Link>  
          </li>
          <li>
          <Link to="/sitemap">Site Map</Link>  
          </li>
          <li>
          <Link to="/travel-guide">Travel Guidelines</Link>  
          </li>
          <li>
            <a href="https://investors.lemontreehotels.com/">
              Investor relations
            </a>
          </li>
        </ul>
        <ul className="bottom-footer-links">
          <li>
            <a href="https://nidhi.nic.in/HotelDivision/Default.aspx">Nidhi</a>
          </li>
          <li>
            <a href="https://utsav.gov.in/">utsav</a>
          </li>
          <li>
            <a href="https://saathi.qcin.org/">Saathi</a>
          </li>
          <li>
          <Link to="/rewards"> Loyalty Program</Link>
          </li>
        </ul>
      </div>
      <div className="col-xs-12">
        <div className="footer-social-section">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
              <p>Join our newsletter</p>
              <div className="footer-subscribe-section">
              <form onSubmit={RegisterUser}>
                {/* <input type="email" placeholder="Enter Email ID" /> */}
                <input required type="email" placeholder="Enter Email ID" name="subscribe_email" value={user?.subscribe_email} onChange={inputHandler} /> 

              <button className="footer-submit-btn" id="reset">
                   Submit
                  </button>
                </form>
                <div className="footer-bottom-form-check">
                <input type="checkbox" id="confirm" className="form-check-input"/>
                
                  <label className="footer-form-check-label">
                    I agree to the <a href="https://www.lemontreehotels.com/privacy-policy">privacy policy.</a>
                  </label>
                </div>
                <div
                  className="loadingPageSubscribe"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 footer-right-social-block">
              <div className="footer-social-icons-block">
                <h4>Follow us</h4>
                <div className="footer-social-icons">
                  <ul>
                    <li className="social-i">
                      <a href="https://www.facebook.com/lemontreehotelsltd" target="_blank">
                        <img src="/static/images/footer-fb-icon.png" alt="" />
                      </a>
                    </li>
                    <li className="social-i">
                      <a href="https://twitter.com/LemonTreeHotels" target="_blank">
                        <img src="/static/images/footer-linkedin-icon.png" alt="" />
                      </a>
                    </li>
                    <li className="social-i">
                      <a href="https://www.instagram.com/lemontreehotels/" target="_blank">
                        <img src="/static/images/footer-insta-icon.png" alt="" />
                      </a>
                    </li>
                    <li className="social-i">
                      <a href="https://www.youtube.com/c/LemonTreeHotelsLtd" target="_blank">
                        <img src="/static/images/footer-youtube-icon.png" alt="" />
                      </a>
                    </li>
                    <li className="social-i">
                      <a href="https://www.threads.net/@lemontreehotels" target="_blank">
                        <img src="/static/images/footer-threads-icon.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

   
        <p className="copy_right_text">
   
          ©2024 by Lemon Tree Hotels Ltd. All Rights Reserved.
        </p>
      </div>
    </div>
  </article>
</footer>


    </div>)
}