import Axios from 'axios';

const axiosBaseURL = Axios.create({
    baseURL:'https://sdsfoundation.com/lth/demo/'
});


export default axiosBaseURL


