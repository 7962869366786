import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import './index.css';  
export class OwlDemo3 extends Component {  
  
  state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 3,
        },
    },
}
        render()  
        {      
          return (  
              <div>  
         
        <div className='container-fluid'>   
         <OwlCarousel className='owl-carousel owl-theme newest-hotel-slider' items={3} margin={8} autoplay ={true} loop={true}  nav={true} dots={true}  center={true} autoplayTimeout={8000} autoplayHoverPause={true} responsive={this.state.responsive} >  
            <div className="item">
            <img src= {'https://www.lemontreehotels.com/assets/front/images/21_21_2023_12_21_03Facade-Evening-Shot-1.jpg'}/>
        
                            <div className="slider-demo2Content">
                                <h4>Lemon Tree Hotel, Rajkot</h4>
                                <a href="#" target="_blank">Explore</a> 
                             </div>
                </div>

                <div className="item">
            <img src= {'https://www.lemontreehotels.com/assets/front/images/21_21_2023_12_21_03Facade-Evening-Shot-1.jpg'}/>
        
                            <div className="slider-demo2Content">
                                <h4>Lemon Tree Hotel, Rajkot</h4>
                                <a href="#" target="_blank">Explore</a> 
                             </div>
                </div>

                <div className="item">
            <img src= {'https://www.lemontreehotels.com/assets/front/images/21_21_2023_12_21_03Facade-Evening-Shot-1.jpg'}/>
        
                            <div className="slider-demo2Content">
                                <h4>Lemon Tree Hotel, Rajkot</h4>
                                <a href="#" target="_blank">Explore</a> 
                             </div>
                </div>



      </OwlCarousel>  
      <div className="slider-demoCount"></div>
      </div>  
      </div>  
          )  
        }  
      }  
        
  
export default OwlDemo3  