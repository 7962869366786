import { useState } from "react";
export default function useCountroom(){
   
  const [countroom, setcountroom] = useState(1);
  

  const roomdecrease = () => {
    if (countroom > 0) {
      setcountroom(count => count - 1);
    }
  };

  const roomincrease = () => {
    if (countroom < 4) {
      setcountroom(count => count + 1);
    }
  };

return[countroom,setcountroom,roomdecrease, roomincrease];
}




