import axios from "axios";
import { useEffect, useState} from "react";
import { Link } from "react-router-dom";
import $ from 'jquery'; 


function HomeVideo() {

  $(document).ready(function () {
    $("#rjInput1").focus(function () {
        $("#filterable-data-rj1").show();          
    });
   
    $("#rjInput1").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#filterable-data-rj1 a").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
    });   

    $("#rjInput1").on("change, blur", function() {
      setTimeout(function() { $("#filterable-data-rj1").slideUp().hide(250) }, 3000)        
});
});

  const[hotelList, sethotelList] = useState([]);
  const allhotelist = ()=> {
      axios.get('https://sdsfoundation.com/lth/demo/getbeapi').then((res)=> {
          sethotelList(res.data.p_data)
      }).catch((err) => {
          // console.log(err)
      })
  }

  useEffect(()=>{

    allhotelist();
  },[]);
  return (
  <>


<video className="video_sc_desktop" poster="https://www.lemontreehotels.com/assets/front/video/video-thumb.webp" src={"https://www.lemontreehotels.com/assets/front/video/lemon-tree-desktop-video-1.mp4"} width="100%" height="" controls="controls"  loop autoPlay muted />


<video className="video_sc_mobile" poster="https://www.lemontreehotels.com/assets/front/video/mobile-thumb.webp" src={"https://www.lemontreehotels.com/assets/front/video/lemon-tree-mobile-video-1.mp4"} width="100%" height="" controls="controls"  loop autoPlay muted />



<div className="search-bar-section frmSearch">
    <input
      type="text"
      placeholder="Where to Next?"
      id="rjInput1"
      // onBlur="clearsearch()"
      className="search-input"
    />
    <div
  className="filterable-data"
  id="filterable-data-rj1"
  style={{ display: "none" }}
>

  <ul className="ulbyrj">
  {
           hotelList.map((item) => 
    <li key={item.hotel_id}>
        <Link className="click-on-item-rj select-propery-btn-rj" to={`/${item.city_slug}`} >
        <span className="hotel-name-rj">{item.city}</span>
        <p className="city-name-rj">City</p>
       </Link> 

       

       
        
       {/* <a
       href="{item.hotel_slug}"
        className="click-on-item-rj select-propery-btn-rj"
      >
        <span className="hotel-name-rj">{item.city}</span>
        <p className="city-name-rj">City</p>
      </a> */}

    </li>
          )
        }

{
     hotelList.map((item) => 
      
    <li key={item.hotel_name}>
   <Link className="click-on-item-rj select-propery-btn-rj" to={`../${item.brand_slug}/${item.city_alias}/${item.hotel_slug}`} >
        <span className="hotel-name-rj">{item.hotel_name}</span>
        <p className="city-name-rj">Hotel</p>
       </Link> 

  

    </li>
          )
        }
  </ul>

  
</div>

    <div id="suggesstion-box" />
  </div>

  </>
  );
}

export default HomeVideo;
