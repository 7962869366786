import './App.css';
import HeaderPage from './Header';
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState} from "react";
import FooterPage from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import axiosBaseURL from "./member/httpCommon";

function HotelListPage() {

  // north zone api
  const[northZone, setnorthZone] = useState([]);
  const allnorthZone = ()=> {
    axiosBaseURL.get('/getzonewiselist').then((res)=> {
        setnorthZone(res.data.north_zone)
      }).catch((err) => {
          // console.log(err)
      })
  }

  
  // south zone api
  const[southZone, setsouthZone] = useState([]);
  const allsouthZone = ()=> {
    axiosBaseURL.get('/getzonewiselist').then((res)=> {
        setsouthZone(res.data.south_zone)
      }).catch((err) => {
          // console.log(err)
      })
  }

   // Central zone api
   const[centralZone, setcentralZone] = useState([]);
   const allcentralZone = ()=> {
    axiosBaseURL.get('/getzonewiselist').then((res)=> {
        setcentralZone(res.data.central_zone)
       }).catch((err) => {
           // console.log(err)
       })
   }

      // East zone api
      const[eastZone, seteastZone] = useState([]);
      const alleastZone = ()=> {
        axiosBaseURL.get('/getzonewiselist').then((res)=> {
            seteastZone(res.data.east_zone)
          }).catch((err) => {
              // console.log(err)
          })
      }

         // West zone api
         const[westZone, setwestZone] = useState([]);
         const allwestZone = ()=> {
          axiosBaseURL.get('/getzonewiselist').then((res)=> {
              setwestZone(res.data.west_zone)
             }).catch((err) => {
                 // console.log(err)
             })
         }   
   

        // international zone api
        const[interZone, setinterZone] = useState([]);
        const allinterZone = ()=> {
          axiosBaseURL.get('/getzonewiselist').then((res)=> {
              setinterZone(res.data.international_zone)
            }).catch((err) => {
                // console.log(err)
            })
        }   

                  
  const navigate = useNavigate()

  useEffect(()=>{
    allnorthZone();
    allcentralZone();
    allsouthZone();
    alleastZone();
    allwestZone();
    allinterZone();
  },[]);

  return (
    <>
      <HeaderPage />
   

      <section class="filters-cs cs-sc-padding country-page-cs pt-5">
            <div class="container">
               <div class="row">
		 <div class="col-md-4">
			<nav class="breadcrumb-cs" aria-label="breadcrumb">
			   <ol class="breadcrumb">
				  <li class="breadcrumb-item"><a href="/">Home</a></li>
				  <li class="breadcrumb-item"><a href="/hotels">Destination</a></li>
				  <li class="breadcrumb-item active" aria-current="page">Hotels</li>
			   </ol>
			</nav>
		 </div>
                  <div class="col-md-8">
                  </div>
               </div>
            </div>
         </section>
      <section className="country-page-cs-list">
        <div className="description-updated-list">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
                    <ul
                      className="nav nav-pills flex-column"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active show"
                          id="North-tab"
                          data-toggle="tab"
                          href="#North"
                          role="tab"
                          aria-controls="North"
                          aria-selected="true"
                        >
                          <img
                            className="me-2"
                            src="../static/images/north-cs.jpg"
                            data-src="../static/images/north-cs.jpg"
                          />
                          North
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="South-tab"
                          data-toggle="tab"
                          href="#South"
                          role="tab"
                          aria-controls="South"
                          aria-selected="false"
                        >
                          <img
                            className="me-2"
                            src="../static/images/south-cs.jpg"
                            data-src="../static/images/south-cs.jpg"
                          />
                          South
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="Central-tab"
                          data-toggle="tab"
                          href="#Central"
                          role="tab"
                          aria-controls="Central"
                          aria-selected="false"
                        >
                          <img
                            className="me-2"
                            src="../static/images/central-cs.jpg"
                            data-src="../static/images/central-cs.jpg"
                          />
                          Central
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="East-tab"
                          data-toggle="tab"
                          href="#East"
                          role="tab"
                          aria-controls="East"
                          aria-selected="false"
                        >
                          <img
                            className="me-2"
                            src="../static/images/east-cs.jpg"
                            data-src="../static/images/east-cs.jpg"
                          />
                          East
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="West-tab"
                          data-toggle="tab"
                          href="#West"
                          role="tab"
                          aria-controls="West"
                          aria-selected="false"
                        >
                          <img
                            className="me-2"
                            src="../static/images/west-cs.jpg"
                            data-src="../static/images/west-cs.jpg"
                          />
                          West
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="International-tab"
                          data-toggle="tab"
                          href="#International"
                          role="tab"
                          aria-controls="International"
                          aria-selected="false"
                        >
                          <img
                            className="me-2"
                            src="../static/images/international-cs.jpg"
                            data-src="../static/images/international-cs.jpg"
                          />
                          International
                        </a>
                      </li>
                    </ul>
                  </div>
               
                  <div className="col-md-7">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="North"
                        role="tabpanel"
                        aria-labelledby="North-tab">
                        <ul>
                        {
          northZone.map((val) => 
                          <li>
                            <a onClick={() => navigate("/"+val.city_slug)}>
                              <FontAwesomeIcon icon={faLocationArrow} />
                              {val.name}
                            </a>  
                            </li>
            )
          }
                        
                          <li className="upcoming-link">
                          <Link target='_blank' to="/upcominghotels"> Upcoming destinations
                          <FontAwesomeIcon icon={faLocationArrow} /></Link>
              
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade show"
                        id="South"
                        role="tabpanel"
                        aria-labelledby="South-tab"
                      >
                       <ul>
                        {
          southZone.map((val) => 
                          <li>


                            <a onClick={() => navigate("/"+val.city_slug)}>
                              <FontAwesomeIcon icon={faLocationArrow} />
                              {val.name}
                            </a>                    </li>
            )
          }
                        
                          <li className="upcoming-link">
                          <Link target='_blank' to="/upcominghotels"> Upcoming destinations
                          <FontAwesomeIcon icon={faLocationArrow} /></Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade show"
                        id="Central"
                        role="tabpanel"
                        aria-labelledby="Central-tab"
                      >
                           <ul>
                        {
          centralZone.map((val) => 
                          <li>


                            <a onClick={() => navigate("/"+val.city_slug)}>
                              <FontAwesomeIcon icon={faLocationArrow} />
                              {val.name}
                            </a>                    </li>
            )
          }
                        
                          <li className="upcoming-link">
                          <Link target='_blank' to="/upcominghotels"> Upcoming destinations
                          <FontAwesomeIcon icon={faLocationArrow} /></Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade show"
                        id="East"
                        role="tabpanel"
                        aria-labelledby="East-tab"
                      >
                         <ul>
                        {
          eastZone.map((val) => 
                          <li>


                            <a onClick={() => navigate("/"+val.city_slug)}>
                              <FontAwesomeIcon icon={faLocationArrow} />
                              {val.name}
                            </a>                    </li>
            )
          }
                        
                          <li className="upcoming-link">
                          <Link target='_blank' to="/upcominghotels"> Upcoming destinations
                          <FontAwesomeIcon icon={faLocationArrow} /></Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="West"
                        role="tabpanel"
                        aria-labelledby="West-tab"
                      >
                           <ul>
                        {
          westZone.map((val) => 
                          <li>


                            <a onClick={() => navigate("/"+val.city_slug)}>
                              <FontAwesomeIcon icon={faLocationArrow} />
                              {val.name}
                            </a>                    </li>
            )
          }
                        
                          <li className="upcoming-link">
                          <Link target='_blank' to="/upcominghotels"> Upcoming destinations
                          <FontAwesomeIcon icon={faLocationArrow} /></Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="International"
                        role="tabpanel"
                        aria-labelledby="International-tab"
                      >
                        <ul>
                        {
          interZone.map((val) => 
                          <li>
                              <a onClick={() => navigate("/"+val.city_slug)}>
                              <FontAwesomeIcon icon={faLocationArrow} />
                              {val.name}
                            </a>  
                          </li>
          )
        }
                        
                          <li className="upcoming-link">
                          <Link target='_blank' to="/upcominghotels"> Upcoming destinations
                          <FontAwesomeIcon icon={faLocationArrow} /></Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
             
            </div>
          </div>
      
        </div>
      </section>

      <FooterPage />


    </>
  )

}

export default HotelListPage;

