import './App.css';
import './index.css'
import FooterPage from './Footer';
import HeaderPage from './Header';
import Owldemo1 from './Owldemo1' 
import OwlDemo2 from './owldemo2';
import OwlDemo3 from './owldemo3';
import OwlDemo4 from './owldemo4';
import HomeVideo from './homevideo';
import $ from 'jquery'; 


const HomePage =() =>{

// on click scroll
  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }
// on click scroll end

const viewimg = (imgname, imgTitle) =>{ 
var img = document.querySelector('.brands_img');
img.src = 'assets/img/' + imgname;
$('.our_brands_img_caption').html(imgTitle);
  }

  
  return (
  <><HeaderPage/>


<section className="banner-section">

<HomeVideo/>

  <div className="icon-scroll">
    <a onClick={handleScroll}>
      <img src="/static/images/M2.png" alt="" />
    </a>
  </div>
</section>

  <section className="top-strip-block" id="scroll-data-bottom">
    <div className="container" style={{ width: "100%", maxWidth: "100%" }}>
      <div className="bg-top-block">
        <div className="left-block-panel">
          <img src="/static/images/white-logo.jpg" alt="" className="strip-logo" />
          <div className="mobile-block">
            <span>save up to 30%</span>
            <a
              href="https://www.lemontreehotels.com/aurika-hotels-and-resorts"
              className="know-more-btn"
            >
              Know More
            </a>
          </div>
        </div>
        <ul className="strip-pointer-listing">
          <li>
            <img
              src="/static/images/white-icon1.jpg"
              alt=""
              className="icon-img"
              style={{ marginTop: 2 }}
            />
            <div className="right-text-block">
              <span>COMPLIMENTRY</span>
              <span>BUFFET breakfast</span>
            </div>
          </li>
          <li>
            <img src="/static/images/white-icon2.jpg" alt="" className="icon-img" />
            <div className="right-text-block">
              <span>evening coffee</span>
              <span>&amp; cookies</span>
            </div>
          </li>
          <li>
            <img src="/static/images/white-icon3.jpg" alt="" className="icon-img" />
            <div className="right-text-block">
              <span>foot reflexology</span> <span>massage</span>
            </div>
          </li>
          <li>
            <img src="/static/images/white-icon4.jpg" alt="" className="icon-img" />
            <div className="right-text-block">
              <span>infinity</span>
              <span>2x coins</span>
            </div>
          </li>
        </ul>
        <div className="right-block-top">
          <span>save up to 30%</span>
          <a
            href="https://www.lemontreehotels.com/aurika-hotels-and-resorts"
            className="know-more-btn"
          >
            Know More
          </a>
        </div>
      </div>
    </div>
  </section>

<section className="hotels-block voffset3">
  <article className="container">
    <div className="row">
      <div className="col-lg-12">
        <Owldemo1/>
      </div>
    </div>
  </article>
</section>



  <section className="choose-your-destination-block">
  <article className="container">
    <h2>Choose your destination</h2>
    <div className="row">
      <div className="col-lg-12">
        <ul className="hotel-list">
          <li>
            <a
              href="/mumbai-hotels"
              target="_blank"
            >
              <img alt="mumbai" src="/static/images/travelForMumbai.jpg" />
              <div className="vactionWorkUpdatedContent">
                <h3>Mumbai</h3>
                <p>
                  5 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/delhi-hotels" target="_blank">

              <img src="/static/images/travelForDelhi.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Delhi</h3>
                <p>
                  4 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/bengaluru-hotels" target="_blank">
              <img src="/static/images/travelForBengaluru.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Bengaluru</h3>
                <p>
                  6 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/ahmedabad-hotels" target="_blank">
              <img src="/static/images/travelForAHMEDABAD.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Ahmedabad</h3>
                <p>
                  3 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/pune-hotels" target="_blank">
              <img src="/static/images/travelPune.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Pune</h3>
                <p>
                  4 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/gurugram-hotels" target="_blank">
              <img src="/static/images/travelGurgaon.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Gurugram</h3>
                <p>
                  8 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/chennai-hotels">
              <img src="/static/images/travelChennai.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Chennai</h3>
                <p>
                  3 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
          <li>
            <a href="/hyderabad-hotels">
              <img src="/static/images/travelHyderabad.jpg" alt="" />
              <div className="vactionWorkUpdatedContent">
                <h3>Hyderabad</h3>
                <p>
                  4 Hotels
                  <i className="fa fa-long-arrow-right" aria-hidden="true" />
                </p>
              </div>
            </a>
          </li>
        </ul>
        <div className="col-lg-12 text-center">
          <a className="explore-more-destination" href="#">
            Explore more destinations
          </a>
        </div>
      </div>
    </div>
  </article>
</section>


<section className="upcoming-hotels">
  <article className="container">
    <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
        <div className="heading mb-4">
          <h2 className="mb-3">Upcoming Hotels</h2>
          <p>
            The renowned hospitality chain is all set to unveil a collection of
            new hotels, promising unforgettable experiences for travelers...
          </p>
          <a className="explore-more-destination" href="upcominghotels">
            Explore
          </a>
        </div>
      </div>
      <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
        <div className="row">
          <div className="col-lg-12">
            <OwlDemo2/>
        </div>
      </div>
    </div>
    </div>
  </article>
</section>


<section className="our-newest-hotels">
  <article className="container-fluid">
    <h2>Our Newest Additions</h2>
    <div className="row">
  <div className="col-lg-12">
  <OwlDemo3/>
    <div className="slider-counter"></div>
  </div>
</div>

  </article>
</section>



<section className="offer-promotion-section">
  <article className="container">
    <h2>Offers &amp; Promotions</h2>
    <div className="row">
      <div className="col-lg-12">
      <OwlDemo4/>
      </div>
  
    </div>
  </article>
</section>


<section className="partner-offer-block">
        <article className="container-fluid p-5">
            <div className="row">
                <div className="col-lg-12 py-4">
                    <h2 className="text-white">Partner Offers</h2>
                    <p className="w-50 text-white">
                        Enjoy a wide range of benefits thanks to our partner's exclusive offers and services. Join our loyalty program today and discover an all-new world of personalised offers and exclusive discounts tailored to your
                        needs.
                    </p>
                    <a href="#" className="btm-explore-btn">Explore</a>
                </div>
            </div>
        </article>
    </section>

  <section className="whats-new-block">
    <article className="container">
      <div className="row">
        <div className="col-lg-12 py-4">
          <h2>What's New</h2>
          <div className="inner-content-block">
            <div className="col-lg-12">
              <div className="row inner-main-section">
                <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12 content-column">
                  <p style={{ marginTop: 18 }}>
                    A CELEBRATION OF MATERNAL LOVE
                    <br />
                    <br />
                    This month, we extend heartfelt wishes to all mothers,
                    infusing our offerings with love and gratitude.
                    <br />
                    <br />
                    We're thrilled to announce our newest additions in Kasauli,
                    Rishikesh, Kathmandu and Chitwan. The opening of Kathmandu
                    was made even more special as it marked our first foray into
                    Nepal as well as the brand clocking 10,000 rooms.
                    <br />
                    <br />
                    <span className="whats-btn">
                      <a href="#" className="btm-explore-btn">
                        Read More
                      </a>
                      <span className="WhatsNewSection_subpara">
                        *Best viewed on a desktop or in landscape mode on a
                        mobile
                      </span>
                    </span>
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12 image-column">
                  <img
                    src="/static/images/may-Sales-Brochure-Cover_page.jpg"
                    alt=""
                    className="custom-img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>

  
  <section className="our-reward-program">
    <article className="container">
      <div className="row">
        <div className="col-lg-12 py-4">
          <h2>Our Rewards Program</h2>
        </div>
      </div>
    </article>
    <article className="reward-banner-section">
      <div className="container">
        <h2>
          Members exclusive rewards and privileges with endless possibilities,
          from free stays and upgrades to special offers and previews, and much
          more...
        </h2>
        <p>Infinity Rewards</p>
        <div className="reward-Btns-section">
          <a href="#" className="btm-explore-btn-white">
            Explore
          </a>
          <a href="#" className="btm-explore-btn">
            Join us
          </a>
        </div>
      </div>
    </article>
  </section>




  
  <section className="our-brands-section cs_our_brands pb-5 bg-light"
  id="updated_our_brand">
  <div className="container">
    <div className="row">
      <div className="heading text-center pb-4 text-center w-100">
        <h4 className="text-uppercase">Our brands</h4>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <figure id="ourBrandsLeftImg" className="left_brands_img">
          <img id="banner"
            className="w-100 brands_img "
            src="assets/img/AHR.jpg"
          />
          <div className="our_brands_img_caption">Aurika, Mumbai Skycity</div>
        </figure>
      </div>
      <div className="col-md-6">
      <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne" onClick={() =>viewimg('AHR.jpg', 'Aurika, Mumbai International Airport')}

          >
           <span className="aurika_brand_label">
            Aurika Hotels &amp; Resorts
          </span>
          <figure className="aurika_brand_img">
           
            <img
              className=" mb-3 w-25 text-center mx-auto"
              src="https://www.lemontreehotels.com/assets/front/images/aurika_color_logo.jpg"
            />
          </figure>
      
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
          <p>
                                          When you stay with us expect an unexpected kind of style. Aurika hotels are exquisitely elegant and yet comfortably informal for you to be just as relaxed as you want to be. 
                                          <a className="AurikaUdaipur-dsk" href="aurika-hotels-resorts">Explore</a>
                                       </p>
          </div>
        </div>

      </div>
   <div className="accordion-item">
        <h2 className="accordion-header">
         <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() =>viewimg('LTP.jpg', 'Lemon Tree Premier, Bhubaneswar')}
          >
          
  <span className="aurika_brand_label">Lemon Tree Premier</span>
  <figure className="aurika_brand_img">
   
    <img
      className="mb-3 w-25 text-center mx-auto "
      src="https://www.lemontreehotels.com/assets/front/images/LTP_newupdated.jpg"
    />
  </figure>
</button>
        </h2>
<div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
          <p>The plush and spacious interiors at Lemon Tree Premier take the zing up a notch. This chain of Upper Midscale business and leisure hotels elevates the Lemon Tree experience while 
           <a className="lthBhubaneswar-dsk" href="https://www.lemontreehotels.com/lemon-tree-premier">Explore</a>
            </p>
          </div>
        </div>
      </div>

 <div className="accordion-item">
        <h2 className="accordion-header">
         <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() =>viewimg('LTH.jpg','Lemon Tree Hotel, Coimbatore')}
          >
          
  <span className="aurika_brand_label">Lemon Tree Hotels</span>
  <figure className="aurika_brand_img">
   
    <img
      className="mb-3 w-25 text-center mx-auto "
      src="https://www.lemontreehotels.com/assets/front/images/LTH-newupdated.jpg"
    />
  </figure>
</button>
        </h2>
<div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
          <p>Lemon Tree Hotel are midscale business and leisure hotels that uplift your spirits at the end of a long day. Like the fruit they are named after, Lemon Tree Hotels are fresh, cool
           <a className="lthBhubaneswar-dsk" href="https://www.lemontreehotels.com/lemon-tree-hotel">Explore</a>
            </p>
          </div>
        </div>
</div>

<div className="accordion-item">
        <h2 className="accordion-header">
         <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() =>viewimg('RFH.jpg', 'Red Fox , Neelkanth')}
          >
          
  <span className="aurika_brand_label">Red Fox By Lemon Tree Hotels</span>
  <figure className="aurika_brand_img">
   
    <img
      className="mb-3 w-25 text-center mx-auto "
      src="https://www.lemontreehotels.com/assets/front/images/red_newupdated.jpg"
    />
  </figure>
</button>
        </h2>
<div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
          <p>Red Fox welcomes you with its bold interiors as well as crisp and clean rooms. These economy hotels delight you with their unbeatable value and reliable safety standards.
           <a className="lthBhubaneswar-dsk" href="https://www.lemontreehotels.com/red-fox-hotel">Explore</a>
            </p>
          </div>
        </div>
</div>

<div className="accordion-item">
        <h2 className="accordion-header">
         <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={() =>viewimg('KPH.jpg', 'Keys Prima By Lemon Tree Hotels, Thekaddy')}
          >
          
  <span className="aurika_brand_label">Keys Prima By Lemon Tree Hotels</span>
  <figure className="aurika_brand_img">
   
    <img
      className="mb-3 w-25 text-center mx-auto "
      src="https://www.lemontreehotels.com/assets/front/images/keys-prima-LTH.jpg"
    />
  </figure>
</button>
        </h2>
<div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
          <p>Our premium brand welcomes you with its stylish features and excellent service. Experience exclusive hospitality with our customised services that make your time with us unforg-
           <a className="lthBhubaneswar-dsk" href="https://www.lemontreehotels.com/keys-prima-hotel">Explore</a>
            </p>
          </div>
        </div>
</div>

<div className="accordion-item">
        <h2 className="accordion-header">
         <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" onClick={() =>viewimg('KSH.jpg', 'Keys Select By Lemon Tree Hotels, Gandhi Ashram, Ahmedabad')}
          >
          
  <span className="aurika_brand_label">Keys Select By Lemon Tree Hotels</span>
  <figure className="aurika_brand_img">
   
    <img
      className="mb-3 w-25 text-center mx-auto "
      src="https://www.lemontreehotels.com/assets/front/images/KeysSelectLogo-colour-MAIN.png"
    />
  </figure>
</button>
        </h2>
<div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
          <p>Our popular brand invites you to enjoy the comfort and convenience at these hotels, whether you are on business or leisure. A warm welcome, efficient service, impeccable hyg-
           <a className="lthBhubaneswar-dsk" href="https://www.lemontreehotels.com/keys-select-hotel">Explore</a>
            </p>
          </div>
        </div>
</div>

<div className="accordion-item">
        <h2 className="accordion-header">
         <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" onClick={() =>viewimg('KLH.jpg','Keys Lite By Lemon Tree Hotels, Tapovan, Rishikesh')}
          >
          
  <span className="aurika_brand_label">Keys Lite By Lemon Tree Hotels</span>
  <figure className="aurika_brand_img">
   
    <img
      className="mb-3 w-25 text-center mx-auto "
      src="https://www.lemontreehotels.com/assets/front/images/KeysLiteLogo-colour-MAIN.png"
    />
  </figure>
</button>
        </h2>
<div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
          <p>This budget brand is targeted at smart consumers who look for affordable and stress free accommodation when they travel. These hotels offer hygienic rooms, multiple ame-
           <a className="lthBhubaneswar-dsk" href="https://www.lemontreehotels.com/keys-lite-hotel">Explore</a>
            </p>
          </div>
        </div>
</div>

    </div>
    


      </div>
    </div>
  </div>
</section>



<FooterPage/>


</>

  )


  
}

export default HomePage;
