import * as React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom";
import $ from 'jquery';
import { useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import select2 from 'select2';
import axiosBaseURL from "./member/httpCommon";
import useCounter from "./useCounter";
import useCounterChild from "./useCounterChild";

import useCountroom from "./useCountroom.js";
import useCountrow from "./useCountrow.js";
import useCalender from "./useCalender.js";

export default function HeaderPage(){
  const[adult,setadult,increase, decrease]=useCounter();
  const[ children,setchildren,Cincrease, Cdecrease]=useCounterChild();
  const[ countroom,setcountroom,roomdecrease, roomincrease]=useCountroom();
  const[ rows,setRows,handleAddRow, postResults, handleRemoveSpecificRow]=useCountrow();
  const[ rangeStart,setRangeStart,selectStartDate,rangeEnd,setRangeEnd,selectEndDate, today1 ]=useCalender();

  const [clicked, setClicked] = useState('');
  const [clicked1, setClicked1] = useState('');
const handleClick = () => {
  clicked ? setClicked('') : setClicked('base-state click-state');
  clicked1 ? setClicked1('') : setClicked1('dblock dnone');
};
 
const handleChange= (e) =>({
  [e.target.name]: e.target.value,
});



 
  $(function () {
    $("#getBeCityHotel").select2();
  });
  const navigate = useNavigate()


// script for switch disable
 $(function () {
    $("#mySwitch").click(function () {
        if ($(this).is(":checked")) {
          $("#txtPassportNumber").attr("disabled", "disabled");

            // $("#txtPassportNumber").focus();
        } else {
          $("#txtPassportNumber").removeAttr("disabled");
        }
    });
});
// script for switch disable end


const [counter, setCounter] = useState(1);

const[hotelList, sethotelList] = useState([]);

const allhotelist = ()=> {    
      axiosBaseURL.get('/getbeapi').then((res)=> {
          sethotelList(res.data.p_data)
      }).catch((err) => {
          // console.log(err)
      })
  }



  $("#getBeCityHotel").on("select2:select", function (e) { 
    var searchar = $(e.currentTarget).val();
    //  console.log("Get Hotel Name pinky"+select_val)
  });


      // selecting brand id start
    const[hotel2, sethotel2] = useState('');
    const allhotels = (e) => {
    e.preventDefault()
    sethotel2(( $("#getBeCityHotel").val()))
    // setbrandid(e.target.value);
    
    console.log("getting Hotel Name "+ hotel2)
    };
    // selecting brand id end



  const navbarDropdown = () => {
    $(".bookbtn_slide_booking_form").slideDown();
    $("#navbarDropdown").hide();
  };

  $("#rooms_childs_showT").click(function(){
    $(".add-rooms-block").toggle();
  });

  const closepop = () => {
    $(".bookbtn_slide_booking_form").slideUp();
    var x = document.getElementById("navbarDropdown");
    $("#navbarDropdown").show();
    x.classList.remove("d_none");
    // x.classList.add("dblk");
  }

useEffect(()=>{
  allhotelist();
  window.scrollTo(0, 0);
},[]);
    return(
<div>
 
<header className="fixed-top">
  <nav className="navbar navbar-expand-lg navbar-light">
    <div className="container-fluid">
      <a className="navbar-brand" href="/">
      <img src="/static/images/logo.png" className="logo" />
            </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <a className="mobile-top-book-now" id="mobile-book-now-btn" href="#">
        Book Now
      </a>



      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

        <li className="nav-item active">
            <Link to="/" className="nav-link active">Home</Link>
        </li>

        <li className="nav-item active">
            <Link to="/hotels" className="nav-link active">Hotels</Link>
        </li>

        <li className="nav-item">
        <Link to="/SpecialOffer" className="nav-link active"> Offers & Promotions</Link>
           </li>

           <li className="nav-item">
           <Link to="/rewards" className="nav-link active">  Rewards Program</Link>

          </li>
          <li className="nav-item">
          <Link to="/our-brands" className="nav-link active">  Our Brands</Link>
                   </li>
          <li className="nav-item">
          <Link to="/sustainability" className="nav-link active"> Sustainability</Link>
          </li>

        </ul>
        <ul className="navbar-nav right-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle top-book-now"
              onClick={navbarDropdown}
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Book Now
            </a>
            <div className="book-btn-dropdown">
              <div className="book-btn-Inner-panel">
                <h4>Enjoy exclusive member discount &amp; more</h4>
                <div className="book-btn-content-inner">
                  <div className="link">
                  <Link to="/member/signup" target="_blank" className="signin-btn">Join</Link>
                    
                  </div>
                </div>
              </div>
            </div>
            <ul
              className="dropdown-menu signin-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <h4>Enjoy exclusive member discount &amp; more</h4>
                <a
                  className="dropdown-item signin-btn"
                  onClick={e => e.preventDefault()}
                  title="Sign-in"
                >
                  Join
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link right-img-icon" aria-current="page" href="#">
              <img src="/static/images/account_person_icon.png" alt="" />
            </a>
            <ul
              className="dropdown-menu signin-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <h4>Sign-in/join</h4>
                <Link to="/signup" target="_blank" className="dropdown-item join-btn">Join</Link>
               
                <Link to="/signin" target="_blank" className="dropdown-item signin-btn">Sign In</Link>
              
                <div className="join_down_part">
                <a
  className="dropdown-item py-2 Modify_booking_log_out manage_book_dsk"
  target="_blank"
  href="https://be.synxis.com/signin?chain=7710&child=0&configcode=initialConfig&level=chain&themecode=initialTheme2&_gl=1*naut34*_gcl_au*ODEzOTczNjkyLjE3MTYyOTEyMDguNzQ3MDk4ODEyLjE3MTgxNzMwMTIuMTcxODE3MzAxMg..*_ga*MTQ0Nzc0OTg1OS4xNzE2Mjg1MjI5*_ga_QGQQBS873K*MTcyMDE1NjM2Ny4xMjIuMS4xNzIwMTY1MjQ1LjUwLjAuMTExNTc2MzE0MQ.."
>
  Manage Bookings
</a>

                </div>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link right-img-icon" aria-current="page" href="#">
              <img src="/static/images/mobile-cs.png" alt="" />
            </a>
            <ul
              className="dropdown-menu signin-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <p className="callno-info">
                  <strong>For Reservations/Queries:</strong>
                  <br />
                  Mob: <a href="tel:+91 9911701701">+91 9911701701</a> <br />
                  Tel: <a href="tel:+91 1244165165">+91 1244165165</a> <br />
                  Email:
                  <a href="mailto:reservations@lemontreehotels.com">
                    reservations@lemontreehotels.com
                  </a>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div className="bookbtn_slide_booking_form" id="box1">
         {/* <button onClick={() => alert('Button clicked!')}>Click me!</button> */}
  {/* <a
    href=""
    id="close1"

    onClick={closepop}
    className="cross-ion"
  >
    X
  </a> */}
    <span id="close1"

   onClick={closepop}
   className="cross-ion"> x </span>
  <div className="header_booking_engine">

    <div className="swtch-btn">
      <span className="suggestion-btn">
        <img src="static/images/informative.png" className="img-fulid" />
      </span>
      <p>Day Rooms</p>
      <div className="switch_new">
        <div className="form-check form-switch">
          <input className="form-check-input"
            type="checkbox"
            id="mySwitch"
            name="checkbox"
            defaultValue="yes"
          />
          <label className="form-check-label" htmlFor="mySwitch" />
        </div>
      </div>
    </div>

    <div className="header-search-select-option">
    {/* <select onChange={allhotels} className="form-control form-select" name="getBeCityHotel" id="getBeCityHotel"> */}
  <select onChange={allhotels} className="form-control form-select" name="getBeCityHotel" id="getBeCityHotel">
                    {/* <option>Please Select Hotel</option> */}
                        {
                            hotelList.map((val) =>

                            <option key={val.synxis_id} value={val.synxis_id}>  { val.hotel_name}  </option>
                            )
                        }
                           {
                            hotelList.map((val) =>
                            <option key={val.synxis_id} value={val.synxis_id}>   <b>{ val.city_name}</b> </option>
                            )
                        }
        </select>

    </div>

    <div className="datepicker-outer">
      <DatePicker
        selectsStart
        selected={rangeStart}
         minDate={today1}
        startDate={rangeStart}
         endDate={rangeEnd}
          className="form-control"
          // onChange={(date) => setStartDate(date)}
        onChange={selectStartDate}
        monthsShown={2}
        shouldCloseOnSelect={true}      
   />

      <div className="input-group-addon">
        <img src="static/images/cal-icon-blue.png" alt="" className="calender-icon" />
      </div>
          </div>
    <div className="datepicker-outer">
    <DatePicker
        selectsEnd
        selected={rangeEnd}
        // startDate={rangeStart}
        endDate={rangeEnd}
        id="txtPassportNumber"
        className="form-control"
        onChange={selectEndDate}
        monthsShown={2}
        shouldCloseOnSelect={true}
        minDate={rangeStart}
         />


            <div className="input-group-addon">
        <img src="static/images/cal-icon-blue.png" alt="" className="calender-icon" />
      </div>
    </div>

     <div className="rooms-child-outer-block">
      <input
        type="text"
        className="rooms-childs-input"
        id="rooms_childs_showT"
        value={`Room(s) ${countroom} - Adult(s) ${adult} - Children ${children}`}
        readOnly=""
      />
      <div className="backdrop d-none close-rooms" data-close=".showmoreT" />
      <div className="showmoreT add-rooms-block mt-2">
      <div className="col-md-12 column">
           
             
                {rows.map((item, idx) => (
                  <div key={idx} className="add-rooms-div">
                    
                    {Array.from(Array(counter)).map((collumn, index) => (
                      <div key={index}>
                  
                        <div className="Add-rooms-2">
                    <p className="rooms-listT">Room {idx + 1}</p>
                    <span className={clicked1 || 'dblock stripT' }>
                      <span className="font16 blackText">                        
                        <span className="latoBlack adultT">{adult}</span> Adults,
                        <span className="latoBlack childT"> {children}</span> Children
                      </span>
                      <a className="font12 appendLeft10 edit_roomT" onClick={handleClick}>Edit </a>
                      {/* <a className="font12 remove_roomT" id="removeT"  onClick={() => handleRemoveSpecificRow(idx)} >
                        Remove
                      </a> */}
                      <a className="font12 remove_roomT" onClick={() => {roomdecrease(); handleRemoveSpecificRow(idx);}}>Remove</a>
                    </span>
                  </div>
                  <div className={clicked || 'base-state row col-12 pr-0 stripT2' }>
                    <div className="col-12 col-md-6 ">
                      <div className="form-group plus-min-style">
                        <div className="rounded-0">
                          <p>Adult(s):</p>

                          <button onClick={decrease}
                            type="button"
                            className="sub-adult"
                            id="sub_adultT"
                          >
                            -
                          </button>
                          <input type="number" onChange={handleChange} className="adult-inputT" id="" value={adult} readOnly></input>
                       
                          <button type="button" className="add" id="" onClick={increase}>
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group plus-min-style">
                        <div className="rounded-0 ">
                          <p>Child (0-6 years):</p>
                          <button type="button" className="sub" id="sub_childT" onClick={Cdecrease}>
                            -
                          </button>
                          <input
                            type="number"
                            className="child-inputT"
                            id="childsT"
                            value={children}
                            readOnly=""
                          />
                          <button type="button" className="add" id="add_childT" onClick={Cincrease}>
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                        {/* <input
                          type="text"
                          collumn={collumn}
                          value={rows[idx][collumn]}
                          index={idx}
                          className="form-control"
                          onChange={(e) => updateState(e)}                             
                          
                        /> */}
                      </div>
                    ))}

                    <div>
             
                    </div>
                  </div>
                ))}
             
        
            <button onClick={() => {roomincrease(); handleAddRow();}} className="btn add-another-room">
              Add Another Room
            </button>
            <button
              onClick={postResults}
              className="btn appy-rooms-add"
            >
          Apply
            </button>
          </div>


 </div>
 {/* <button onClick={handleAddDiv}>Add</button> */}
      {/* <div className="App">
      {Array.from(Array(counter)).map((item, idx) => (
        <div key={idx} id={`id-${idx}`} className="aab">
          <div>
            <input type="text" />
            <button onClick={() => removeNode(idx)}>Remove</button>
          </div>
        </div>
      ))}

      <button onClick={handleAddDiv}>Add</button>
    </div> */}
    </div>

    <button className="strip-book-now-btn" onClick={() => window.open(`https://be.synxis.com/?adult=${adult}&arrive=${rangeStart}&chain=7710&child=${children}&currency=INR&depart=${rangeEnd}&hotel=${$("#getBeCityHotel").val()}&level=hotel&locale=en-US&rooms=${countroom}&shell=SBE&start=availresults&template=SBE&theme=initialTheme2`)}>
    Book Now
    </button>




  </div>
</div>

</header>



    </div>)
}