import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import './index.css';  
export class OwlDemo4 extends Component {  
  
  state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 3,
        },
    },
}
        render()  
        {      
          return (  
              <div>  
         
        <div className='container-fluid' >   
         <OwlCarousel className='owl-carousel owl-theme offer-permotion-slider verticalTop' items={3} margin={8} autoplay ={true} loop={true}  nav={false} center={true} dots="false" autoplayTimeout={8000} autoplaySpeed={4000} responsive={this.state.responsive}> 
       <div className="item">
          <img src= {'static/images/mobile-img1.jpg'}/>            
            <div className="bottom-offer-block">
              <div className="offer_new_heading_paragraph">
                <h4 className="heading-text">Member’s rate</h4>
              </div>
              <a href="#" className="bottom-explore-btn">
                Explore
              </a>
            </div>
          </div>

          <div className="item">
          <img src= {'static/images/mobile-img2.jpg'}/>            
            <div className="bottom-offer-block">
              <div className="offer_new_heading_paragraph">
                <h4 className="heading-text">Happy Hour</h4>
              </div>
              <a href="#" className="bottom-explore-btn">
                Explore
              </a>
            </div>
          </div>

          <div className="item">
          <img src= {'static/images/mobile-img3.jpg'}/>            
            <div className="bottom-offer-block">
              <div className="offer_new_heading_paragraph">
                <h4 className="heading-text">Sunday Redefined</h4>
              </div>
              <a href="#" className="bottom-explore-btn">
                Explore
              </a>
            </div>
          </div>

          <div className="item">
          <img src= {'static/images/mobile-img4.jpg'}/>            
            <div className="bottom-offer-block">
              <div className="offer_new_heading_paragraph">
              <h4 className="heading-text">Day Rooms <span className="day_rooms_time">( 10am to 5pm )</span></h4>
              </div>
              <a href="#" className="bottom-explore-btn">
                Explore
              </a>
            </div>
          </div>


      </OwlCarousel>  
      <div className="slider-counter"></div>
      </div>  
      </div>  
          )  
        }  
      }  
        
  
export default OwlDemo4  