import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import './index.css';  
export class Owldemo1 extends Component {  
    
  state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 3,
        },
    },
}
        render()  
        {  
          return (  
            <div>  
     
       <div className='container-fluid' >            
        <OwlCarousel items={3}  
          className="owl-theme aurika-top-hotel-slider" responsive={this.state.responsive} 
          loop  
          nav  
          margin={8}>  
           <div className='item'><img  className="img" src= {'/static/images/aurika-hotels-and-resorts2.jpg'}/>
           <div className="hotel-bottom-info">
                                <h3>
                                    <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/udaipur/udaipur-hotels">
                                        Aurika, Mumbai Skycity - <br/>
                                        Luxury by Lemon Tree Hotels
                                    </a>
                                </h3>
                            </div>
                            <div className="btns-block">
                                <a href="#" className="hotel-new-explore-btn">Explore</a>
                                <a className="hotel-new-booknow-btn" href="#">Book Now</a>
                            </div></div>  

                         

           <div className='item'><img  className="img" src= {'/static/images/aurika-hotels-and-resorts1.jpg'}/>
           <div className="hotel-bottom-info">
                                <h3>
                                    <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/udaipur/udaipur-hotels">
                                        Aurika, Udaipur - <br/>
                                        Luxury by Lemon Tree Hotels
                                    </a>
                                </h3>
                            </div>
                            <div className="btns-block">
                                <a href="#" className="hotel-new-explore-btn">Explore</a>
                                <a className="hotel-new-booknow-btn" href="#">Book Now</a>
                            </div>
                            </div>  
           <div className='item'><img  className="img" src= {'/static/images/aurika-hotels-and-resorts3.jpg'}/>
           <div className="hotel-bottom-info">
                                <h3>
                                    <a href="https://www.lemontreehotels.com/aurika-hotels-resorts/udaipur/udaipur-hotels">
                                        Aurika, Coorg -<br/>
                                        Luxury by Lemon Tree Hotels
                                    </a>
                                </h3>
                            </div>
                            <div className="btns-block">
                                <a href="#" className="hotel-new-explore-btn">Explore</a>
                                <a className="hotel-new-booknow-btn" href="#">Book Now</a>
                            </div>
                            </div>  
           
      </OwlCarousel>  
      </div>  
  
      </div>  
          )  
        }  
      }  
        
  
export default Owldemo1 