import * as React from "react";
export default function useCalender(){
   
  const [rangeStart, setRangeStart] = React.useState(new Date)
  const defaultEndDate = new Date()
  defaultEndDate.setDate(defaultEndDate.getDate() + 1)
  const [rangeEnd, setRangeEnd] = React.useState(defaultEndDate)
  const today1 = new Date()

  const selectStartDate = d => {
    setRangeStart(d)
  }

  const selectEndDate = d => {
    setRangeEnd(d)
  }


return[rangeStart,setRangeStart,selectStartDate,rangeEnd,setRangeEnd,selectEndDate, today1];
}

