import { useState } from "react";

export default function useCountrow(){
   
  const [rows, setRows] = useState([
    {"rowNumber" : {
      "adult" : 5, kids : 4}}
  ]);

//   const rows = [{
 
//     id:""
//   }
// ]

  const handleAddRow = () => {
    const item = {
      id:"" ,
    

    };
    setRows([...rows, item]);    
  };

  
  // console.log("this is test", rows); 
  const postResults = () => {
    console.log(rows); // there you go, do as you please
  };
  const handleRemoveSpecificRow = (idx) => {
    const tempRows = [...rows]; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    setRows(tempRows);
  };


return[rows,setRows,handleAddRow, postResults, handleRemoveSpecificRow];
}


