import {useState} from 'react';
export default function useCounterChild(){
 
// counter for child start
const [children, setchildren] = useState(0);
//decrease counter CHILD
const Cdecrease = () => {
  if (children > 0) {
    setchildren(count => count - 1);
  }
};

//increase counter CHILD
const Cincrease = () => {
    if (children < 2) {
      setchildren(count => count + 1);
    }
};
// counter for child end

    return[children,setchildren,Cincrease, Cdecrease];
}

