import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import './index.css';  
export class OwlDemo2 extends Component {  
  
  state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
}
        render()  
        {      
          return (  
              <div>         
        <div className='container-fluid' >   
         <OwlCarousel className='owl-carousel owl-theme upcoming-hotel-slider' items={4} margin={8} autoplay ={true} loop={true}  nav={true} autoplayTimeout={8000} autoplaySpeed={4000} responsive={this.state.responsive} navText={[
            'Prev<span></span>',
            'Next<span></span>'
          ]} >  
  <div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/chandausi-img.jpg'}/>
    <div className="otherHotels-cs-sc-item-content">
      <div className="coming-soon-hotels-content-heading">
        <h4>
          Lemon Tree Hotel,
          <br /> Chandausi
        </h4>
      </div>
      <a
        href="javascript:void(0);"
        data-toggle="modal"
        data-target="#UpcomingModaludaipur"
        className="readMore-cs"
      >
        Read More
      </a>
    </div>
  </div>
</div>

<div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/udaipur-imgg.jpg'}/>
  <div className="otherHotels-cs-sc-item-content">
                                           <div className="coming-soon-hotels-content-heading">
                                              <h4>Keys Lite Hotel,<br/> Udaipur</h4>
                                           </div>
                                           <a href="javascript:void(0);" data-toggle="modal" data-target="#UpcomingModaludaipur" className="readMore-cs">Read More</a> 
                                        </div>
  </div>
</div>

<div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/Noida-imgg.jpg'}/>
<div className="otherHotels-cs-sc-item-content">
  <div className="coming-soon-hotels-content-heading">
    <h4>Lemon Tree Hotel,<br/> Noida</h4>
  </div>
  <a href="javascript:void(0);" data-toggle="modal" data-target="#UpcomingModaludaipur" className="readMore-cs">Read More</a> 
</div>
  </div>
</div>

<div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/somnath-imgg.jpg'}/>
  <div className="otherHotels-cs-sc-item-content">
    <div className="coming-soon-hotels-content-heading">
      <h4>Lemon Tree Resort, <br/> Somnath</h4>
    </div>
    <a href="javascript:void(0);" data-toggle="modal" data-target="#UpcomingModaludaipur" className="readMore-cs">Read More</a> 
</div>
  </div>
</div>

<div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/02_Jul_2023_04_20_28Mossoorie.jpg'}/>
  <div className="otherHotels-cs-sc-item-content">
    <div className="coming-soon-hotels-content-heading">
      <h4>Lemon Tree Resort, <br/> Mussoorie</h4>
    </div>
    <a href="javascript:void(0);" data-toggle="modal" data-target="#UpcomingModaludaipur" className="readMore-cs">Read More</a> 
</div>
  </div>
</div>

<div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/06_Nov_2023_01_32_32goa1.jpg'}/>
  <div className="otherHotels-cs-sc-item-content">
    <div className="coming-soon-hotels-content-heading">
      <h4>Lemon Tree Resort, <br/> Anjuna, Goa</h4>
    </div>
    <a href="javascript:void(0);" data-toggle="modal" data-target="#UpcomingModaludaipur" className="readMore-cs">Read More</a> 
</div>
  </div>
</div>

<div className="item">
  <div className="otherHotels-item">
  <img className="img" src= {'static/images/06_Nov_2023_03_40_03kanh.jpg'}/>
  <div className="otherHotels-cs-sc-item-content">
  <div className="coming-soon-hotels-content-heading">
    <h4>Lemon Tree Resort, <br/> Kanha</h4>
  </div>
  <a href="javascript:void(0);" data-toggle="modal" data-target="#UpcomingModaludaipur" className="readMore-cs">Read More</a> 
</div>
  </div>
</div>



      </OwlCarousel>  
      <div className="slider-counter"></div>
      </div>  
      </div>  
          )  
        }  
      }  
        
  
export default OwlDemo2  